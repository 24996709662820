.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.table-container {
  margin: 20px;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  text-align: left;
}

.new-app {
  background-color: #f3f4f6;
}

.up {
  color: green;
  margin-left: 5px;
}

.down {
  color: red;
  margin-left: 5px;
}

select {
  margin-left: 15px;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.landing {
  text-align: center;
  padding: 50px;
  background-color: #f8f9fa;
  font-family: Arial, sans-serif;
}

.landing form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing input, .landing button {
  padding: 10px;
  margin: 10px 0;
  width: 300px;
}

.landing button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.landing button:hover {
  background-color: #0056b3;
}

.main-container {
  display: flex;
}

.marketplace-column {
  width: 50%;
  padding: 20px;
}

.diff-app-card {
  margin: 10px 0;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: all 0.3s;
  display: flex;
  align-items: flex-start;
}

.app-card-content {
  display: flex;
  align-items: flex-start;
}

.app-icon {
  margin-right: 15px;
  width: 100px;
  height: 100px;
}

.app-name {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
}

.app-description {
  font-size: 14px;
  color: #777;
  margin-left: 10px;
}

.diff-app-card a {
  text-decoration: none;
  color: #000;
}

.diff-app-card:hover {
  background-color: rgba(255,255,255,0.1);
  transform: scale(1.03);
}

.intro-text {
  font-size: 18px;
  margin-bottom: 30px;
}

.thank-you-message {
  font-size: 20px;
  color: #28a745;
  margin-top: 20px;
}

.subscription-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 0 auto;
}

.subscription-form input, .subscription-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  margin-bottom: 10px;
}

.subscription-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.subscription-form button:hover {
  background-color: #0056b3;
}

.error-message {
  color: #e74c3c;
  margin-bottom: 15px;
  font-size: 14px;
}

.upcoming-apps {
  margin-top: 50px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.app-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.app-card {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  flex-basis: calc(33.33% - 20px);
  max-width: 240px;
}

.live-update-info {
  font-size: 14px;
  color: #888;
  margin-bottom: 15px;
}
.apps-list {
  max-height: calc(15 * 40px); /* Assuming each app takes approximately 40px in height */
  overflow-y: auto;
  padding-right: 10px; /* To account for the scrollbar width and avoid content shifting */
}
.metrics-box .metrics-container {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between;
}

.metric-block {
  flex: 1;
  max-width: 45%;  /* Adjust as needed to ensure only two boxes fit side by side */
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 10px 5px;  /*  Added a vertical margin for separation when they wrap */
  background-color: #fff;
}
/* 
.metric-block {
  flex: 1;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 0 10px;
  background-color: #fff;
} */

.metrics-box h4 {
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

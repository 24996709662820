/* NewAppsPage.css */

.new-apps-list {
  margin: 20px auto;
  max-width: 800px;
  padding: 10px;
}

.app-item {
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  padding: 10px;
  border-radius: 8px;
  margin: 10px 0;
  text-decoration: none;
  color: inherit; /* Ensures the text within the card has default color */
  transition: background-color 0.2s ease, transform 0.2s ease; /* Animation for hover effects */
}

.app-item:hover {
  background-color: #f9f9f9; /* A light background on hover */
  transform: translateY(-3px); /* Slightly raises the card on hover */
}

.app-logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-right: 15px;
}

.app-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.app-name {
  font-weight: bold;
  margin-bottom: 5px;
  color: #333; /* Darker text color for more contrast */
}

.app-description {
  color: #666;
  margin-bottom: 10px;
}
